import { CMS_INSTANCE, NEXT_INSTANCE } from '@/lib/api'
import dayjs from 'dayjs'
import { create } from 'zustand'

let abortController = null

const useAuth = create((set, get) => ({
  userMe: null,
  isUserMeLoading: true,
  myAppointments: null,
  betterStaffMenu: [
    {
      title: 'better-staff',
      icon: 'far fa-coin',
      path: '/my-account/better-staff',
      activeSlug: 'better-staff',
    },
    {
      title: 'my-profile',
      icon: 'far fa-user',
      path: '/my-account/profile',
      activeSlug: 'profile',
    },
    {
      title: 'logout',
      icon: 'far fa-arrow-right-from-bracket',
      path: '/my-account/logout',
      activeSlug: 'logout',
    },
  ],
  myAccountMenu: [
    {
      title: 'my-appointment',
      icon: 'far fa-calendar',
      path: '/my-account/appointment',
      activeSlug: 'appointment',
    },
    {
      title: 'my-b-point',
      icon: 'far fa-coin',
      path: '/my-account/b-point',
      activeSlug: 'b-point',
    },
    {
      title: 'my-profile',
      icon: 'far fa-user',
      path: '/my-account/profile',
      activeSlug: 'profile',
    },
    {
      title: 'logout',
      icon: 'far fa-arrow-right-from-bracket',
      path: '/my-account/logout',
      activeSlug: 'logout',
    },
  ],
  setUserMe: (userMe) => set({ userMe }),
  setMyAppointments: (myAppointments) => set({ myAppointments }),
  getUserMe: async ({ yearMonth = '', locale = 'th' } = {}) => {
    try {
      if (abortController) {
        abortController.abort()
      }
      abortController = new AbortController()
      set({ isUserMeLoading: true })
      // if yearMonth is not provided, use `Y-m`
      let formattedCurrentYearMonth = yearMonth
      if (!yearMonth) {
        formattedCurrentYearMonth = dayjs().format('YYYY-MM')
      }
      const res = await NEXT_INSTANCE.get(
        `/next-api/auth/me?point_month_data=${formattedCurrentYearMonth}&locale=${locale}`,
        {
          signal: abortController.signal,
        }
      )
      set({ userMe: res.data?.data })
      return res.data
    } catch (err) {
      console.error(err?.response?.data)
    } finally {
      set({ isUserMeLoading: false })
    }
  },
  getUserAge: () => {
    if (!get().userMe?.dob) {
      return null
    }
    return dayjs().diff(get().userMe.dob, 'year')
  },
  updateProfile: async (data) => {
    try {
      const res = await NEXT_INSTANCE.put(`/next-api/auth/me`, data)
      set({ userMe: res.data?.data })
      return res.data
    } catch (err) {
      console.error(err?.response?.data)
      return err?.response?.data
    }
  },
  requestOTP: async ({ channel, channelInput, token }) => {
    try {
      const res = await NEXT_INSTANCE.post(`/next-api/auth/request-otp`, {
        channel,
        channelInput,
        token,
      })
      return res.data
    } catch (err) {
      console.error(err?.response?.data)
      return err?.response?.data
    }
  },
  verifyOTP: async ({
    channelInput,
    otp,
    refId,
    token,
    roleToCreate = 'customer',
  }) => {
    try {
      const res = await NEXT_INSTANCE.post(`/next-api/auth/verify-otp`, {
        otp,
        channelInput,
        refId,
        token,
        roleToCreate,
      })
      return res.data
    } catch (err) {
      console.error(err?.response?.data)
      return err?.response?.data
    }
  },
  logout: async (shouldJailToken = true) => {
    try {
      const res = await NEXT_INSTANCE.post(`/next-api/auth/logout`, {
        shouldJailToken,
      })
      set({ userMe: null })
    } catch (err) {
      console.error(err?.response?.data)
    }
  },
}))

export default useAuth
